import "./SearchAssetForm.scss";
import { useEffect, useRef } from "preact/compat";
import type { JSX } from "preact";
import { useState } from "preact/hooks";

import useModal from "../../hooks/useModal";
import Modal from "../Modal";
import ComboBox from "../ComboBox";
import { fetchWithRetries } from "@lib/util.ts";
import useOnScreen, { range } from "../../hooks/useOnScreen";
import Selected from "../Selected";

import { SearchIcon } from "../SearchInput/SearchIcon";
import { networks } from "../../config/networks";
import type { Coin, CoinsResponse } from "./types";

const ALL_NETWORKS = Array.from(networks.keys()).join(",");

const LIMIT = 50;

type Props = {
  hash: string;
  translates: {
    asset: {
      label: string;
      placeholder: string;
    };
    network: {
      all: string;
      label: string;
    };
    title: string;
  };
};

export default function SearchAssetForm({ hash, translates }: Props) {
  const searchRef = useRef<HTMLInputElement>(null);
  const resultRef = useRef<HTMLDivElement>(null);
  const [tokenText, setTokenText] = useState("");
  const [currentTokenText, setCurrentTokenText] = useState("");
  const [hasMoreTokens, setHasMoreTokens] = useState(true);
  const [tokenList, setTokenList] = useState<Array<Coin>>([]);
  const [imageHost, setImageHost] = useState("");
  const [selectedNetwork, setSelectedNetwork] = useState(ALL_NETWORKS);
  const [page, setPage] = useState(0);
  const { ratio } = useOnScreen(resultRef, {
    threshold: range(0, 1, 0.01, 2),
  });

  const { isShowing, hide, show } = useModal("search", hash);
  let timeout: ReturnType<typeof setTimeout>;

  useEffect(() => {
    if (ratio === 100 && hasMoreTokens) {
      setPage((v) => v + 1);
    }
  }, [ratio]);

  useEffect(() => {
    if (isShowing && searchRef?.current) {
      searchRef?.current?.focus();
    }
  }, [isShowing, searchRef.current]);

  const handleSearch = (e: Event) => {
    e.preventDefault();
    show();
    if (searchRef.current) {
      searchRef.current.focus();
    } else {
      setTimeout(() => {
        searchRef?.current?.focus();
      }, 100);
    }
  };

  useEffect(() => {
    if (!isShowing) {
      return;
    }
    const searchCoins = async (coin: string) => {
      const params = new URLSearchParams();
      if (coin) {
        if (!new RegExp(/[a-zA-Z0-9_-]+/).test(coin)) return;
        params.set("searchText", coin);
      }
      const currentPage = coin === currentTokenText ? page : 0;
      params.set("active", "true");
      params.set("offset", `${page * LIMIT}`);
      params.set("limit", LIMIT.toString());
      params.set("networkIds", selectedNetwork);

      fetchWithRetries(
        `https://api.tangem-tech.com/v1/coins?${params.toString()}`,
        {},
      )
        .then((data: CoinsResponse) => {
          const { coins, total, limit, offset, imageHost } = data;
          setImageHost(imageHost);
          setHasMoreTokens(total > limit + offset);
          setTokenList((v) => (currentPage === 0 ? coins : [...v, ...coins]));
          setCurrentTokenText(coin);
        })
        .catch(() => {
          setHasMoreTokens(false);
          setTokenList([]);
          setPage(0);
          setCurrentTokenText(coin);
        });
    };

    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => searchCoins(tokenText), 150);
  }, [isShowing, tokenText, page, selectedNetwork]);

  const onSearchChange: JSX.InputEventHandler<HTMLInputElement> = (e) => {
    const { value } = e.currentTarget;
    if (!value.match(/^[\p{L}0-9_-]*$/gu)) {
      e.currentTarget.value = tokenText;
      return;
    }
    setTokenText(value);
    setPage(0);
  };

  const onChangeNetwork = (selectedItem: string, index: number) => {
    setSelectedNetwork(index === 0 ? ALL_NETWORKS : selectedItem);
    setPage(0);
  };

  const buildImage = (id: string) => {
    let isBroken = false;

    const imageSrc = `${imageHost}large/${id}.png`;
    const image = new Image();

    image.src = imageSrc;

    image.addEventListener(
      "load",
      () => {
        isBroken = false;
      },
      false,
    );

    image.addEventListener(
      "error",
      () => {
        isBroken = true;
      },
      false,
    );

    return isBroken;
  };

  const renderTokens = () => {
    return tokenList.map((token) => (
      <>
        <div className="assets-list__item-img">
          {buildImage(token.id) === false ? (
            <img
              src={`${imageHost}large/${token.id}.png`}
              alt={token.name}
              className="assets-list__item-img-tag"
            />
          ) : (
            <span className="">{token.symbol[0]}</span>
          )}
        </div>
        <div className="assets-list__item-info">
          <h3 className="assets-list__item-title">{token.name}</h3>
          <span className="assets-list__item-coin">{token.symbol}</span>
        </div>
        <div className="assets-list__item-networks">
          <p className="assets-list__item-text">
            {token.networks
              .map((item) => networks.get(item.networkId))
              .join(", ")}
          </p>
        </div>
      </>
    ));
  };

  function onReset(e: Event) {
    e.preventDefault();
    setTokenText("");
    setPage(0);
  }

  return (
    <>
      <div className="form" id="searchAssetInput">
        <label htmlFor="search" className="asset__label">
          <span className="visually-hidden">{translates.asset.label}</span>
        </label>
        <input
          className="asset__input"
          id="search"
          type="search"
          autoComplete="off"
          placeholder={translates.asset.placeholder}
          value={tokenText}
          onClick={handleSearch}
        />
        {tokenText && tokenText.length > 0 ? (
          <button
            aria-label="Clear input"
            className="asset__input-button asset__input-button_clear"
            onClick={onReset}
          ></button>
        ) : (
          ""
        )}
      </div>
      <Modal isShowing={isShowing} hide={hide}>
        <section className="form__modal container">
          <button onClick={hide} className="form__close"></button>
          <h2 className="form__title only-desktop">
            <Selected
              text={translates.title}
              selectedClass="text_gradient"
              class="text_white"
            />
          </h2>

          <div className="form__inputs">
            <div className="asset__input-wrap">
              <label
                htmlFor="modal-search"
                className="asset__label-rect label_small"
              >
                <span className="only-not-desktop">
                  {translates.asset.label}
                </span>
                <span className="only-desktop">{translates.asset.label}</span>
              </label>
              <div className="asset__input-container">
                <span className="asset__input-search-icon">
                  <SearchIcon />
                </span>
                <input
                  className="asset__input"
                  id="modal-search"
                  type="text"
                  value={tokenText}
                  onInput={onSearchChange}
                  autoComplete="off"
                  placeholder={translates.asset.placeholder}
                  ref={searchRef}
                />
                {tokenText && tokenText.length > 0 ? (
                  <button
                    aria-label="Clear input"
                    className="asset__input-button asset__input-button_clear"
                    onClick={onReset}
                  ></button>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="only-not-mobile">
              <ComboBox onChange={onChangeNetwork} />
            </div>
          </div>
          <div className="form__result" id="root">
            <div className="assets-list">
              <div className="assets-list__wrap">{renderTokens()}</div>
              <div className="assets-list_end" ref={resultRef}></div>
            </div>
          </div>
        </section>
      </Modal>
    </>
  );
}
